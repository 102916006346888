import React from "react";
import { useCarrito } from "../../../hook/UseCarrito";

import "./megusta.css";

export default function Megusta() {
  const { galeria, meGusta } = useCarrito();

  const productosFavoritos = galeria.filter((producto) => meGusta[producto.id]);

  return (
    <div className="productos-favoritos">
      <h2> Favoritos</h2>
      {productosFavoritos.length === 0 ? (
        <p>No tienes favoritos</p>
      ) : (
        productosFavoritos.map((producto) => (
          <div key={producto.id} className="box-favoritos">
            <img src={producto.imagen} alt={producto.nombre} />
            <h3>{producto.nombre}</h3>
            <p>{producto.descripcion}</p>
            <p> US$ {producto.precio}</p>
          </div>
        ))
      )}
    </div>
  );
}
