import Reac, { useEffect, useState } from "react";
import "./catalogo.css";

import { rutaAPI } from "../../../config";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import { useCarrito } from "../../../hook/UseCarrito";
import { useFilters } from "../../../hook/UseFilters";

export default function Catalogo() {
  const {
    addCarrito,
    toggleMeGusta,
    meGusta,
    galeria,
    verCarrito,
    valor,
    removeFromCarrito,
  } = useCarrito();

  const { filters, setFilters, filtersProducts } = useFilters();
  const productosFiltrados = filtersProducts(galeria);

  const [minPrice, setMinPrice] = useState(0);

  useEffect(() => {
    verCarrito();
  }, []);

  const handleIncrement = (productoId) => {
    const newQuantity = (valor[productoId] || 0) + 1;
    addCarrito(productoId, newQuantity); // Usamos addCarrito para actualizar el carrito
  };

  const handleDecrement = (productoId) => {
    const currentQuantity = valor[productoId] || 0; // Cantidad actual del producto
    const newQuantity = currentQuantity - 1;
    if (newQuantity > 0) {
      // Actualizamos la cantidad en el carrito si es mayor a 0
      addCarrito(productoId, newQuantity);
    } else {
      // Eliminamos el producto si llega a 0
      removeFromCarrito(productoId);
    }
  };

  const handMinPrice = (event) => {
    setFilters((prevState) => ({
      ...prevState,
      minPrice: event.target.value,
    }));
  };

  return (
    <div>
      <div className="range">
        <label htmlFor="minPrice">{""} Precio a partir de:</label>
        <input
          type="range"
          id="minPrice"
          min="0"
          max="10000"
          onChange={handMinPrice}
          value={filters.minPrice}
        ></input>
        <span>US$ {filters.minPrice}</span>
      </div>
      <div className="lugar">
        {galeria.length === 0 ? (
          <p>Cargando productos...</p> // Muestra un mensaje mientras los productos se cargan
        ) : (
          productosFiltrados.map((producto) => (
            <div
              className="fotoCont"
              key={producto.id}
              onContextMenu={(e) => e.preventDefault()}
            >
              <span className="mg" onClick={() => toggleMeGusta(producto.id)}>
                <FontAwesomeIcon
                  icon={faHeart}
                  className={`icons ${
                    meGusta[producto.id] ? "corazonActivo" : "corazonInactivo"
                  }`}
                />
              </span>

              <img
                src={producto.imagen}
                alt={`photo-${producto.id}`}
                onContextMenu={(e) => e.preventDefault()}
              />
              <div className="description">
                <h5>US$ {producto.precio}</h5>
                <p>{producto.descripcion}</p>
              </div>
              {/* Si el producto tiene 0 en cantidad, muestra solo el botón de agregar */}
              {valor[producto.id] === 0 || !valor[producto.id] ? (
                <div className="botonera">
                  <button
                    className="sumar"
                    onClick={() => handleIncrement(producto)}
                  >
                    Agregar +
                  </button>
                </div>
              ) : (
                <div className="boton">
                  <button
                    className="sumarMitad"
                    onClick={() => handleIncrement(producto)}
                  >
                    Agregar +
                  </button>
                  <button
                    className="eliminarMitad"
                    onClick={() => handleDecrement(producto.id)}
                  >
                    Eliminar -
                  </button>
                </div>
              )}
              {/* Si el producto tiene más de 0 en cantidad, muestra los botones de agregar y eliminar */}
            </div>
          ))
        )}
      </div>
    </div>
  );
}
