import { Children, createContext, useContext, useState } from "react";

import { rutaAPI } from "../config";

export const CarritoContext = createContext();

export function CarritoProvider({ children }) {
  const [carrito, setCarrito] = useState([]);
  const [meGusta, setMegusta] = useState({});
  const [galeria, setGaleria] = useState([]);
  const [valor, setValor] = useState({});
  const [imgCarrito, setImgCarrito] = useState([]); //Imagenes de los productos

  //1- *PETICION GET DE LA API PARA GALERIA*/
  const getData = async () => {
    try {
      const url = `${rutaAPI}/api/mostrarCarrito`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Error al obtener los datos");
      }
      return response;
    } catch (err) {
      return err;
    }
  };

  //2 - Funcion para mostrar los datos con tetch
  const verCarrito = async () => {
    try {
      const response = await getData();
      const data = await response.json();

      if (!data || !Array.isArray(data.docs)) {
        throw new Error(
          "La respuesta de la api no contiene los datos esperados"
        );
      }
      //Actualizar galeria con los productos obtenidos de la api
      const productos = data.docs.map((doc) => ({
        id: doc.id,
        imagen: `${rutaAPI}/api/verImgCarrito/${doc.id}`,
        nombre: doc.nombre, //suponiendo que los productos tienen un nombre
        precio: doc.precio,
        descripcion: doc.descripcion,
      }));

      setGaleria(productos);

      setValor((prevValor) => {
        const nuevasCantidades = {};
        productos.forEach((prod, index) => {
          nuevasCantidades[prod.id] = prevValor[prod.id] || 0; // Si ya existe en el estado, lo conserva
        });
        return nuevasCantidades;
      });
      setImgCarrito(productos.map((prod) => prod.imagen));

      // Inicializamos el estado 'megusta' si no ha datos previos
      setMegusta((prevMegusta) => {
        if (Object.keys(prevMegusta).length === 0) {
          //si me gusta esta vacio, creamos una estructura de "me gusta" para los productos
          const nuevosMeGusta = {};
          productos.forEach((prod) => {
            nuevosMeGusta[prod.id] = false; // No me gusta ninguno al principio
          });
          return nuevosMeGusta;
        }
        return prevMegusta; //Si ya existe, mantenemos el estado
      });
    } catch (error) {
      console.error("error la obtener los datos", error);
    }
  };

  //-------------------FUNCION ACTUALIZAR CARRITO-------------------
  const addCarrito = (producto, quantity) => {
    setCarrito((prevCarrito) => {
      // Si la cantidad es cero, eliminamos el producto del carrito
      if (quantity === 0) {
        return prevCarrito.filter((item) => item.producto.id !== producto);
      }

      // Si el producto ya existe, actualizamos su cantidad
      const existingItem = prevCarrito.find((item) => item.id === producto.id);
      if (existingItem) {
        return prevCarrito.map((item) =>
          item.id === producto.id ? { ...item, quantity } : item
        );
      }
      return [...prevCarrito, { producto, quantity }];
    });
    // Actualizamos también el estado de las cantidades (valor)
    setValor((prevValor) => ({
      ...prevValor,
      [producto.id]: quantity,
    }));
  };


  const updateCarritoQuantity = (productoId, newQuantity) => {
    setCarrito((prevCarrito) =>
      prevCarrito.map((item) =>
        item.producto.id === productoId
          ? { ...item, quantity: newQuantity } // Actualiza la cantidad
          : item
      )
    );
  
    // Actualiza el estado 'valor' para reflejar el cambio
    setValor((prevValor) => ({
      ...prevValor,
      [productoId]: newQuantity,
    }));
  };
  

  const removeFromCarrito = (productoId) => {
    setCarrito((prevCarrito) =>
      prevCarrito.filter((item) => item.producto.id !== productoId)
    );
    // También eliminar del estado 'valor'
    setValor((prevValor) => {
      const updatedValor = { ...prevValor };
      delete updatedValor[productoId];
      return updatedValor;
    });
  };



  const toggleMeGusta = (productoId) => {
    console.log("Tocaste el corazón del producto con id:", productoId);
    setMegusta((prevMegusta) => {
      const newMeGusta = { ...prevMegusta };
      newMeGusta[productoId] = !newMeGusta[productoId];
      console.log(newMeGusta);
      return newMeGusta;
    });
  };

  const getTotalItems = () => {
    return Object.values(carrito).reduce(
      (total, item) => total + item.quantity,
      0
    );
  };

  //-------------------FUNCION ACTUALIZAR CORAZON-------------------
  const getTotalLikes = () => {
    return Object.values(meGusta).filter((like) => like === true).length;
  };

  return (
    <CarritoContext.Provider
      value={{
        carrito,
        meGusta,
        galeria,
        valor,
        verCarrito,
        setValor,
        setMegusta,
        setGaleria,
        addCarrito,
        updateCarritoQuantity,
        removeFromCarrito,
        toggleMeGusta,
        getTotalItems,
        getTotalLikes,
      }}
    >
      {children}
    </CarritoContext.Provider>
  );
}
