import logo from "./logo.svg";
import React, { useContext, useEffect, useState } from "react";
import { AdminProvider, AdminContext } from "./context/adminContext";
import AdminLayout from "./AdminLayout/AdminLayout";
import Admin from "./AdminLayout/administrador/Admin";
import Login from "./AdminLayout/administrador/login/Login";

import "./App.css";
import Home from "./Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CursorLight from "./cursosLight/CursorLight";
import { LenguajeProvider } from "./context/lenguaje";

import Renders from "./renders/Renders";
import Diseño from "./diseño/Diseño";
import CrearAdmin from "./AdminLayout/administrador/crearAdmin/CrearAdmin";
import Codigo from "./codigo/Codigo";
import { CarritoProvider } from "./context/carritoContext";
import { FiltersProvider } from "./context/filter";
function App() {
  /*==========ESTADO DE USUARIO==========*/
  const { adminLogueado, setAdminLogueado } = useContext(AdminContext);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem("ACCESS_TOKEN");
      const expirationTime = localStorage.getItem("EXPIRATION_TIME");

      if (expirationTime && Date.now() > expirationTime) {
        localStorage.removeItem("ACCESS_TOKEN");
        localStorage.removeItem("EXPIRATION_TIME");
        localStorage.removeItem("ADMINISTRADOR");
        setAdminLogueado(null); //Limpio el estado en el contexto
        window.location.href = "/"; //Redirigo a la pagina de inicio o a la pagina de login
      }
    };

    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 60000); // cada minuto
    return () => clearInterval(interval); // Limpiar al desmontar
  }, [setAdminLogueado]);

  return (
    <AdminProvider>
      <LenguajeProvider>
        <CarritoProvider>
          <FiltersProvider>
            <div className="App">
              <Router>
                <Routes>
                  <Route
                    path="admin"
                    element={adminLogueado ? <AdminLayout /> : <Login />}
                  ></Route>
                  <Route exact path="/crearAdmin" element={<CrearAdmin />} />
                  <Route index element={<Home />} />
                  <Route exact path="/renders" element={<Renders />} />
                  <Route exact path="/diseño" element={<Diseño />} />
                  <Route exact path="/codigo" element={<Codigo />} />
                </Routes>
              </Router>
            </div>
          </FiltersProvider>
        </CarritoProvider>
      </LenguajeProvider>
    </AdminProvider>
  );
}

export default App;
