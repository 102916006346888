import React, { useState } from "react";

import "./notas.css";

export default function Notas() {
  const [notas, setNotas] = useState("");
  const [nuevaNota, setNuevaNota] = useState([]);

  const colores = {

    red: "rgba(255, 112, 107, 0.973)",
    yellow: 'rgba(250, 217, 28, 0.973)',
    skyBlue: 'rgba(92, 247, 239, 0.973)'
  }

  const colours = ()=>{

   const valoresColores = Object.values(colores); //Extrae los valores de los colores convirtiendo los valores en un array
   const indiceAleatorio = Math.floor(Math.random() * valoresColores.length);
   return valoresColores[indiceAleatorio];
    
  }

  const onChangeAnotaciones = (e) => {
    const texto = e.target.value;
    setNotas(texto);
  };

  const fechas = () => {
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();
    const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
    const dia = fechaActual.getDate().toString().padStart(2, "0");
    const horas = fechaActual.getHours().toString().padStart(2, "0"); // Horas (0-23)
    return `${dia}/${mes}/${anio} : ${horas}hrs`;
  };

  const guardarAnotacion = () => {
    if (notas.trim() === "") {
      // Si la nota esta vacia, no hacer nada
      return;
    }
    //Crear u objeto con el texto de la nota y fecha

    const nuevaNotaObj = {
      texto: notas,
      fecha: fechas(),
      color: colours(), //Asignar el color aleatorio
    };

    //Agrega la nueva nota al array de notas existentes
    setNuevaNota([...nuevaNota, nuevaNotaObj]);
    //Limpiar texArea
    setNotas("");
  };

  const borrarTextArea = () => {
    setNotas("");
  };

  const borrarTodas = () => {
    setNuevaNota([]);
  };

  const eliminarUna = (index) => {
    const nuevasNotas = nuevaNota.filter((_, i) => i !== index);
    setNuevaNota(nuevasNotas);
  };

  return (
    <div className="notas">
      <div className="recuadro">
        <h3>Escribe una nota</h3>
        <textarea onChange={onChangeAnotaciones} value={notas}></textarea>
        <div className="botonesNotas">
          <button className="guardar" onClick={guardarAnotacion}>
            Guardar
          </button>
          <button className="borrar" onClick={borrarTextArea}>
            Borrar
          </button>
        </div>
      </div>
      {nuevaNota.length > 0 && (
        <div className="apartado">
          <div className="notasGrid">
            {nuevaNota.map((nota, index) => (
              <div className="notita" key={index} style={{backgroundColor: nota.color}}>
                <button
                  className="eliminar"
                  id="eliminarNota"
                  onClick={() => eliminarUna(index)}
                >
                  x
                </button>
                <p>{nota.texto}</p>
                <span className="fecha">{nota.fecha}</span>
              </div>
            ))}
          </div>
          <button className="eliminar todasDelete" onClick={borrarTodas}>
            x
          </button>
        </div>
      )}
    </div>
  );
}
