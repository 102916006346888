import { useContext } from "react";
import { FilterContext } from "../context/filter";

export function useFilters() {
  const { filters, setFilters } = useContext(FilterContext);

  const filtersProducts = (obj) => {
    return obj.filter((item) => {
      return item.precio >= filters.minPrice;
    });
  };

  return { filters, filtersProducts, setFilters };
}
