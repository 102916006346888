import React, { useEffect, useState } from "react";

import "./tictac.css";

export default function TicTac() {
  const [board, setBoard] = useState(Array(9).fill(""));
  const [circle, setCircle] = useState(true);
  const [wins, setWins] = useState(false);
  const [winningCombinations, setWinnginCombination] = useState([]);
  const [winner, setWinner] = useState("");

  const handleClick = (index) => {
    if (board[index] !== "") return;

    const newBoard = [...board];
    newBoard[index] = circle ? "O" : "X";
    setBoard(newBoard);
    setCircle(!circle);

    const winningCombo = checkFin(newBoard);

    if (winningCombo) {
      setWins(true);
      setWinnginCombination(winningCombo);
      setWinner(newBoard[winningCombo[0]]);
      setTimeout(() => {
        resetGame();
      }, 2000);
    }else if (!newBoard.includes("")){
      setTimeout(()=>{
        resetGame();
      }, 2000)
    }
  };

  const resetGame = () => {
    setBoard(Array(9).fill(""));
    setWins(false);
    setCircle(true);
    setWinnginCombination([]);
    setWinner("");
  };

  const checkFin = (currentBoard) => {
    const winningCombinations = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let combo of winningCombinations) {
      const [a, b, c] = combo;
      if (
        currentBoard[a] !== "" &&
        currentBoard[a] === currentBoard[b] &&
        currentBoard[a] === currentBoard[c]
      ) {
        return combo;
      }
    }
    return null;
  };

  return (
    <div className="tateti">
      <div className="equipos">
        <div className="jugador">
          <span className={circle ? "jugador1Seleccionado" : "jugador1"}>
            {"O"}
          </span>
          <p>Jugador 1</p>
        </div>
        <div className="jugador">
          <span className={!circle ? "jugador2Seleccionado" : "jugador2"}>
            {"X"}
          </span>
          <p>Jugador 1</p>
        </div>
      </div>
      <div className="board">
        {board.map((value, index) => (
          <div
            className={`cuadrado ${index %2 === 0? "par": "impar"}`}
            onClick={() => handleClick(index)}
            key={index}
          >
            <span
              className={
                value === "O" ? "circle" : value === "X" ? "equis" : ""
              }
            >
              {value}
            </span>
          </div>
        ))}
        {wins && (
          <div
            className={`line ${
              winningCombinations[0] === 0 &&
              winningCombinations[1] === 1 &&
              winningCombinations[2] === 2
                ? "horizontal-top"
                : winningCombinations[0] === 3 &&
                  winningCombinations[1] === 4 &&
                  winningCombinations[2] === 5
                ? "horizontal-middle"
                : winningCombinations[0] === 6 &&
                  winningCombinations[1] === 7 &&
                  winningCombinations[2] === 8
                ? "horizontal-bottom"
                : winningCombinations[0] === 0 &&
                  winningCombinations[1] === 3 &&
                  winningCombinations[2] === 6
                ? "vertical-left"
                : winningCombinations[0] === 1 &&
                  winningCombinations[1] === 4 &&
                  winningCombinations[2] === 7
                ? "vertical-middle"
                : winningCombinations[0] === 2 &&
                  winningCombinations[1] === 5 &&
                  winningCombinations[2] === 8
                ? "vertical-right"
                : winningCombinations[0] === 0 &&
                  winningCombinations[1] === 4 &&
                  winningCombinations[2] === 8
                ? "diagonal-main"
                : "diagonal-counter"
            }`}
          ></div>
        )}
      </div>
      {wins && (
        <div className="cartel">
          {" "}
          <h1>{`¡${winner} ganó!`}</h1>{" "}
        </div>
      )} 
    </div>
  );
}
